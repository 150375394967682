import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useManualQuery } from 'graphql-hooks'
import { Button, Card, Collapse, Modal, notification, Table, Tooltip, Typography } from 'antd'
import { CloseCircleOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import * as Sentry from '@sentry/react'

import { MicrosoftTeams } from '../../services/auth/microsoft/microsoft'
import { track } from '../../services/analytics/analytics'
import { getMyProfileData } from '../../graphql/custom-queries'
import holidayDataWrapper from '../../util/holiday-data-wrapper'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { addCorrelationId } from '../../store/NotificationsSlice'
import { selectUserIdSlice } from '../../store/UserIdSlice'
import ExternalLink from '../../components/external-link-icon'
import { getPrefferedLanguage } from '../../util/get-preffered-language'
import { setLocale } from '../../store/LocaleSlice'

import UserLeaveQuotas from '@vacationtracker/shared/components/user-leave-quotas'
import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../../components/circular-progress'
import LeaveForm from '@vacationtracker/shared/components/leave-form'
import UserTodayOff from '../../components/user-today-off'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import {
  columnsIndex,
  columnsStartEndDate,
  columnsLeaveTypeName,
  columnsDuration,
  columnsReason,
  columnsApprover,
  columnsStatus,
  columnsDenyReason
} from '../../components/leaves-columns'

import { ICompany } from '@vacationtracker/shared/types/company'
import { ILeaveRequestCancelledEvent, ILeaveRequestCreatedEvent } from '@vacationtracker/shared/types/leave-request'
import { sendCoreEvent, validateLeaveRequest } from '../../services/api/vacationtracker'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { ILeaveFormSaveData } from '@vacationtracker/shared/components/leave-form/types'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../store/leave-request-action-event-slice'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'


const { Panel } = Collapse
const { confirm } = Modal
const { Paragraph, Text} = Typography

const MyProfileTabComponent = () => {
  const msAuth = new MicrosoftTeams()
  const { formatMessage } = useIntl()

  const { userId } = useAppSelector(selectUserIdSlice)
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [company, setCompany] = useState<ICompany | null>(null)
  const [user, setUser] = useState({
    locationId: '',
    teamId: '',
    email: '',
    platform: '',
    workWeek: '',
    workWeekType: '',
    workWeekInfo: '',
    timestamp: '',
    id: '',
    name: '',
    imageUrl: '',
    role: '',
    isAdmin: false,
    status: '',
    startDate: '',
    team: {
      id: '',
      name: '',
    },
    location: {
      id: '',
      name: '',
      workWeek: [],
      firstDayOfWeek: 0,
      rolloverNeverExpireDays: false,
      leavePolicies: [],
      resetQuotas: '',
      rolloverExpiryMonth: 0,
      rolloverExpiryDay: 0,
      rolloverExpiryAfterDays: 0,
      timezone: '',
    },
    locale: LocaleEnum.en,
    hourFormat: HourFormatEnum.twentyFour,
  })
  const [todayOff, setTodayOff]: any = useState({})
  const [upcomingLeaves, setUpcomingLeaves]: any = useState([])
  const [historyLeaves, setHistoryLeaves]: any = useState([])
  const [moreHistoryLeaves, setMoreHistoryLeaves]: any = useState([])
  const [pendingLeaves, setPendingLeaves]: any = useState([])
  const [upcomingHolidays, setUpcomingHolidays]: any = useState()
  const [moreUpcomingHolidays, setMoreUpcomingHolidays]: any = useState()
  const [leaveDays, setLeaveDays]: any = useState()
  const [visibleLeaveForm, setVisibleLeaveForm] = useState(false)
  const [createLeaveLoader, setCreateLeaveLoader] = useState(false)
  const [showMoreHolidays, setShowMoreHolidays] = useState<boolean>(false)
  const [showMoreHistory, setShowMoreHistory] = useState<boolean>(false)
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)
  const [isWebDesktopClient, setIsWebDesktopClient] = useState(false)

  useEffect(() => {
    if (leaveRequestActionEvent) {
      fetchUser(userId)
      dispatch(setLeaveRequestActionEvent(null))
    }
  }, [leaveRequestActionEvent])

  useEffect(() => {
    if (userId) {
      fetchUser(userId)
    }
  }, [userId])

  const [fetchUserQuery] = useManualQuery(getMyProfileData)

  const fetchUser = async (id: string) => {
    try {
      const response = await fetchUserQuery({
        variables: {
          id,
          date: moment().format('YYYY-MM-DD'),
          pendingLeavesDate: moment().subtract(2, 'years').startOf('year').format('YYYY-MM-DD'),
        },
      })

      const msContext = await msAuth.getContext()
      const user = response.data.getUser
      const userLanguagePreferance = getPrefferedLanguage(user.locale, msContext.app.locale.toLowerCase())
      // https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/hostclienttype?view=msteams-client-js-latest
      setIsWebDesktopClient(msContext?.app?.host?.clientType === 'web' || msContext?.app?.host?.clientType === 'desktop')
      setUser({
        ...user,
        locale: userLanguagePreferance.locale,
      })
      dispatch(setLocale(userLanguagePreferance))

      setCompany(response.data.getCompany)
      setTodayOff(response.data.getUser.today)
      setUpcomingLeaves(response.data.getUser.upcomingLeaves.sort(
        (a: any, b: any) => a.startDate < b.startDate ? -1 : 1
      ).map(l => {
        return {
          ...l,
          hourlyLeaveAccounting: Boolean(response?.data?.getCompany?.hourlyLeaveAccounting),
        }
      }))
      const allHistoryLeaves = response.data.getUser.history
        .filter(leave => leave.status !== 'OPEN')
        .map(l => ({...l, hourlyLeaveAccounting: Boolean(response?.data?.getCompany?.hourlyLeaveAccounting)}))
        .sort((a: any, b: any) => a.startDate > b.startDate ? -1 : 1)
      setHistoryLeaves(allHistoryLeaves.splice(0, 5))
      setMoreHistoryLeaves(allHistoryLeaves)
      setPendingLeaves(response.data.getUser.pendingLeaves.sort(
        (a: any, b: any) => a.startDate < b.startDate ? -1 : 1
      ).map(l => {
        return {
          ...l,
          hourlyLeaveAccounting: Boolean(response?.data?.getCompany?.hourlyLeaveAccounting),
        }
      }))
      setLeaveDays(response.data.getUser.leaveDays)

      const holidays: any = []
      response.data.getUser.location.holidays.forEach(holidaysByYear => {
        if (holidaysByYear.year >= moment().format('YYYY')) {
          holidaysByYear.holidays.forEach(holiday => {
            if (holiday.date >= moment().format('YYYY-MM-DD')) {
              holidays.push({
                date: holiday.date,
                name: holiday.name,
                multiDayId: holiday.multiDayId,
                isHalfDay: holiday.isHalfDay,
              })
            }
          })
        }
      })
      const upcomingHolidaysList: any[] = holidayDataWrapper(holidays)

      setUpcomingHolidays(upcomingHolidaysList.splice(0, 1))
      setMoreUpcomingHolidays(upcomingHolidaysList)

      setIsLoading(false)
    } catch (err: any) {
      track('MICROSOFT_TAB_MY_PROFILE_ERROR', {
        error: err.toString(),
        platform: 'microsoft',
      })
      console.log('error fetching user by id', err)
    }
  }

  const cancelLeave = async (leave) => {
    try {
      const response = await sendCoreEvent<Partial<ILeaveRequestCancelledEvent>>({
        eventType: 'LEAVE_REQUEST_CANCELLED',
        eventGroup: 'USER_LEAVE_REQUEST',
        leaveRequestId: leave.id,
        userId: leave.user.id,
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'app.updatedSuccessfully' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      // dispatch(setNotifications([ ...notifications, response.correlationId ]))
      dispatch(addCorrelationId(response.correlationId))
      track('MICROSOFT_TAB_MY_PROFILE_LEAVE_CANCELLED', {
        platform: 'microsoft',
      })
    } catch (error) {
      track('MICROSOFT_TAB_MY_PROFILE_ERROR', {
        error: error.toString(),
        platform: 'microsoft',
      })
    }
  }

  const handleCancelLeave = (leave) => {
    confirm({
      title: formatMessage({ id: 'app.cancelLeave' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'app.cancelLeaveConfirmText' }),
      okText: formatMessage({ id: 'app.yes' }),
      cancelText: formatMessage({ id: 'app.no' }),
      onOk() {
        cancelLeave(leave)
      },
    })
  }

  const handleSubmit = async (data: ILeaveFormSaveData) => {
    setCreateLeaveLoader(true)
    try {
      const eventBody: Partial<ILeaveRequestCreatedEvent> = {
        eventType: 'LEAVE_REQUEST_CREATED',
        eventGroup: 'USER_LEAVE_REQUEST',
        ...data,
        userId,
      }
      await validateLeaveRequest(eventBody)

      const response = await sendCoreEvent<Partial<ILeaveRequestCreatedEvent>>(eventBody)

      track('MICROSOFT_TAB_MY_PROFILE_LEAVE_REQUESTED', {
        platform: 'microsoft',
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'app.updatedSuccessfully' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      dispatch(addCorrelationId(response.correlationId))

      setCreateLeaveLoader(false)
      setVisibleLeaveForm(false)
    } catch (err: any) {
      track('MICROSOFT_TAB_MY_PROFILE_ERROR', {
        error: err.toString(),
        platform: 'microsoft',
      })
      setCreateLeaveLoader(false)
      setVisibleLeaveForm(false)
      if (err.response?.data?.error) {
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: formatMessage({ id: err.response.data.error }),
          duration: 0,
        })
      } else {
        Sentry.captureException(err)
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: (
            <Paragraph>
              <Text code copyable>{ JSON.stringify(err, null, 4) }</Text>
            </Paragraph>
          ),
          duration: 0,
        })
      }
    }
  }

  const handleShowMore = (target: 'history' | 'holidays') => {
    switch (target) {
      case 'history':
        setShowMoreHistory(!showMoreHistory)
        break
      case 'holidays':
        setShowMoreHolidays(!showMoreHolidays)
        break
      default:
        break
    }
  }

  const columnsUpcoming = [
    columnsIndex,
    columnsStartEndDate(user.locale, user.hourFormat),
    columnsLeaveTypeName,
    columnsDuration,
    columnsReason,
    columnsDenyReason,
    columnsApprover,
    {
      title: 'Actions',
      dataIndex: 'id',
      className: 'actions',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id, row: any) => {
        return (
          <Tooltip placement="top" title={<IntlMessages id="app.cancelLeave" />}>
            <Button type="link" onClick={() => handleCancelLeave(row)}>
              <CloseCircleOutlined style={{ color: '#f50' }} />
            </Button>
          </Tooltip>
        )
      },
    },
  ]

  const columnsPending = [
    columnsIndex,
    columnsStartEndDate(user.locale, user.hourFormat),
    columnsLeaveTypeName,
    columnsDuration,
    columnsReason,
    {
      title: 'Actions',
      dataIndex: 'id',
      className: 'actions',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id: string, row: any) => {
        return (
          <>
            <Button style={{ marginLeft: 5 }} onClick={() => handleCancelLeave({
              ...row,
              user: {
                id: userId,
              },
            })} type="primary" size="small" danger>
              <IntlMessages id="app.cancel" />
            </Button>
          </>
        )
      },
    },
  ]

  const columns = [{
    title: <IntlMessages id="app.name" />,
    dataIndex: 'name',
    key: 'name',
    width: '40%',
  }, {
    title: 'Date(s)',
    dataIndex: 'date',
    width: '30%',
    key: 'date',
    // eslint-disable-next-line react/display-name
    render: (date, row) => {
      return (<>
        <FormattedDate value={date} format="YYYY-MM-DD" locale={user.locale} />
        {row.multiDayId && <>- <FormattedDate value={row.endDate} format="YYYY-MM-DD" locale={user.locale}/></>}
        {row.isHalfDay && <><IntlMessages id="app.halfDayInParenthesis" /></>}
      </>)
    },
  }, {
    title: <IntlMessages id="dashboard.days" />,
    dataIndex: 'date',
    key: 'day',
    width: '30%',
    // eslint-disable-next-line react/display-name
    render: (date, row: any) => {
      return (<>
        <FormattedDate value={date} format="dddd" locale={user.locale} /> {row.multiDayId && <>- <FormattedDate value={row.endDate} format="dddd" locale={user.locale}/>
        </>}
      </>)
    },
  }]

  const columnsHistory = [
    { ...columnsIndex, width: '2%' },
    { ...columnsStartEndDate(user.locale, user.hourFormat), width: '30%' },
    { ...columnsLeaveTypeName, width: '13%' },
    { ...columnsDuration, width: '10%' },
    { ...columnsReason, width: '20%' },
    { ...columnsDenyReason, width: '20%' },
    { ...columnsApprover, width: '15%' },
    { ...columnsStatus(formatMessage), width: '10%' },
  ]

  const moreColumnsHistory = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: '2%',
      render: (date, row, index: number) => {
        return (index + 6)
      },
    },
    { ...columnsStartEndDate(user.locale, user.hourFormat), width: '30%' },
    { ...columnsLeaveTypeName, width: '13%' },
    { ...columnsDuration, width: '10%' },
    { ...columnsReason, width: '20%' },
    { ...columnsApprover, width: '15%' },
    { ...columnsStatus(formatMessage), width: '10%' },
  ]

  const handleTransitionToRequestLeaveTab = async () => {
    if (isWebDesktopClient) {
      try {
        await msAuth.goTo('requestleave')
      } catch (error) {
        notification.error({
          message: formatMessage({ id: 'error.goToRequestLeaveTab' }),
          description: formatMessage({ id: error?.message }),
          duration: 0,
        })
      }
    } else {
      setVisibleLeaveForm(true)
    }
  }

  return (
    <>
      {isLoading ?
        <CircularProgress /> :
        <>
          <Card style={{ margin: 30 }}>
            <div className="user-info">
              <div className="sidebar-avatar-row">
                <UserAvatar id={user.id} name={user.name} avatarSize={40} avatar={user.imageUrl} shape="square" />
                <span className="sidebar-avatar-name">
                  {user.name}
                  <br />
                  <strong>{company?.name}</strong>
                </span>
              </div>
              <div className="topbar-actions">
                <Button type="primary" style={{ marginRight: 5 }} onClick={() => {handleTransitionToRequestLeaveTab()}}>
                  <IntlMessages id="app.requestLeave" />
                </Button>
                <Button type="primary" target="_blank" style={{ marginRight: 5 }}
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/signin?platform=microsoft&redirect=/app/dashboard?lang=${user?.locale}`}
                >
                  <IntlMessages id="app.openDashboard" /> <ExternalLink />
                </Button>
                <Button type="primary" target="_blank" style={{ marginRight: 5 }} href="https://vacationtracker.crisp.help/en/">
                  <IntlMessages id="app.helpdesk" /> <ExternalLink />
                </Button>
              </div>
            </div>
          </Card>
          {todayOff.length > 0 &&
            <div style={{ margin: 30 }}>
              {todayOff.map(off => {
                if (off.startDate) {
                  return <UserTodayOff key={off.id} todayOff={off} locale={user.locale} />
                }
              })}
            </div>
          }
          {pendingLeaves.length > 0 &&
            <Card title={<IntlMessages id="app.pendingLeaveRequests" />} style={{ margin: 30 }} bodyStyle={{ padding: 0 }}>
              <Table
                className='table-horizontal-scroll'
                locale={{ emptyText: <IntlMessages id="user.noUpcomingLeaves" /> }}
                dataSource={pendingLeaves}
                columns={columnsPending}
                rowKey={record => record.id}
                pagination={false}
              />
            </Card>
          }
          <Card title={<IntlMessages id="app.scheduledLeaves" />} style={{ margin: 30 }} bodyStyle={{ padding: 0 }}>
            <Table
              className='table-horizontal-scroll'
              locale={{
                emptyText: <IntlMessages id="user.noUpcomingLeaves" />,
              }}
              dataSource={upcomingLeaves}
              columns={upcomingLeaves.length > 0 ? columnsUpcoming : columnsUpcoming.filter(leaveColumn => leaveColumn.key !== 'statusReason')
              }
              rowKey={record => record.id}
              pagination={false}
            />
          </Card>
          <UserLeaveQuotas
            quotas={leaveDays}
            userId={user.id}
            amIAdmin={false}
            IntlMessages={IntlMessages}
            location={user.location}
            userName={user.name}
            hourlyLeaveAccounting={Boolean(company?.hourlyLeaveAccounting)}
            locale={user.locale}
          />
          <Card
            title={<IntlMessages id="dashboard.upcomingHolidays" />}
            style={{ margin: 30 }}
            bodyStyle={{ padding: 0 }}
            // eslint-disable-next-line max-len
            extra={
              <a href={`${process.env.REACT_APP_DASHBOARD_URL}/signin?platform=microsoft&redirect=/app/dashboard?lang=${user?.locale}`} target="_blank" rel="noopener noreferrer">
                <IntlMessages id="app.openDashboard" /> <ExternalLink />
              </a>
            }
          >
            <Table
              className='table-horizontal-scroll'
              dataSource={upcomingHolidays}
              columns={columns}
              loading={isLoading}
              rowKey={record => record}
              pagination={false}
            />
            {moreUpcomingHolidays.length > 0 &&
              <Collapse
                ghost
                onChange={() => handleShowMore('holidays')}
              >
                <Panel
                  header={<IntlMessages id={showMoreHolidays ? 'app.showLess' : 'app.showMore'} />}
                  key="1"
                  showArrow={false}
                >
                  <Table
                    className='table-horizontal-scroll'
                    showHeader={false}
                    dataSource={moreUpcomingHolidays}
                    columns={columns}
                    loading={isLoading}
                    rowKey={record => record}
                    pagination={false}
                  />
                </Panel>
              </Collapse>
            }
          </Card>
          <Card
            title={<IntlMessages id="app.leaveHistory" />}
            style={{ margin: 30 }}
            bodyStyle={{ padding: 0 }}
            extra={
              <a href={`${process.env.REACT_APP_DASHBOARD_URL}/signin?platform=microsoft&redirect=/app/my-profile?lang=${user?.locale}`} target="_blank" rel="noopener noreferrer">
                <IntlMessages id="myProfile.openMyProfile" /> <ExternalLink />
              </a>
            }
          >
            <Table
              className='table-horizontal-scroll'
              locale={{
                emptyText: <IntlMessages id="myProfile.dontHaveAnyHistory" values={{ name: user.name }} />,
              }}
              dataSource={historyLeaves}
              columns={columnsHistory}
              rowKey={record => record.date}
              pagination={false}
            />
            {moreHistoryLeaves.length > 0 &&
              <Collapse
                ghost
                onChange={() => handleShowMore('history')}
              >
                <Panel
                  header={<IntlMessages id={showMoreHistory ? 'app.showLess' : 'app.showMore'} />}
                  key="1"
                  showArrow={false}
                >
                  <Table
                    className='table-horizontal-scroll'
                    showHeader={false}
                    dataSource={moreHistoryLeaves}
                    columns={moreColumnsHistory}
                    loading={isLoading}
                    rowKey={record => record.name}
                    pagination={false}
                  />
                </Panel>
              </Collapse>
            }
          </Card>
          <Modal
            title={<IntlMessages id='app.requestLeave' />}
            visible={visibleLeaveForm}
            footer={false}
            onCancel={() => { setVisibleLeaveForm(false) }}
          >
            <LeaveForm
              authUserId={user.id}
              authUserRole={user.role}
              loading={createLeaveLoader}
              listOfUsers={[user]}
              onSave={(data: ILeaveFormSaveData) => {
                (async () => {
                  await handleSubmit(data)
                })()
              }}
              formType={'request'}
              onCancel={() => { setVisibleLeaveForm(false) }}
              modalForm={true}
              hourFormat={user?.hourFormat}
            />
          </Modal>
        </>
      }
    </>
  )
}

export default MyProfileTabComponent

import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Result, Typography } from 'antd'
import * as Sentry from '@sentry/react'
import { Auth } from 'aws-amplify'
import { ClientContext, useManualQuery } from 'graphql-hooks'
import { Buffer } from 'buffer'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { useLocalStorage } from 'usehooks-ts'

import { identify, page, track } from '../../services/analytics/analytics'
import { useAppDispatch } from '../../store/hooks'
import { setUserId } from '../../store/UserIdSlice'
import { setTheme } from '../../store/ThemeSlice'
import { setLocale } from '../../store/LocaleSlice'
import { getMicrosoftToken, getUserId } from '../../services/api/microsoft'
import { getUserByEmail } from '../../services/api/vacationtracker'
import { MicrosoftTeams } from '../../services/auth/microsoft/microsoft'
import { getPrefferedLanguage } from '../../util/get-preffered-language'
import { ThemeType } from '../../constants/ThemeSetting'

import ExternalLink from '../../components/external-link-icon'
import IntlMessages from '../../util/IntlMessages'
import Notifications from '../Notifications'
import CircularProgress from '../../components/circular-progress'

import { ICheckUserId } from '@vacationtracker/shared/types/company'
import { IMicrosoftUser, IPageError, IPageWrapperProps } from './types'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { setAuthCompany } from '../../store/auth-company-slice'
import { getUserData } from '../../graphql/custom-queries'

const { Paragraph, Text } = Typography

interface IAuthorization {
  Authorization: string
  host: string
}

const dashboardCompleteSignupUrl = `${process.env.REACT_APP_DASHBOARD_URL}/signup?platform=microsoft&utm_source=microsoftteams&utm_medium=tabs`

const PageWrapper = ({
  PageComponent,
}: IPageWrapperProps) => {
  const msAuth = new MicrosoftTeams()
  const dispatch = useAppDispatch()
  const gqlClient = useContext(ClientContext)
  const [fetchUser] = useManualQuery(getUserData)

  const [loggedIn, setLoggedIn] = useState(false)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [amplifySignInResponse, setAmplifySignInResponse] = useState<any | null>(null)
  const [microsoftUser, setMicrosoftUser] = useState<IMicrosoftUser | null>(null)
  const [pageError, showPageError] = useState<IPageError | null>(null)
  const [companyExist, setCompanyExist] = useState(true)
  const [consentLink, setConsentLink] = useState(`https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`)
  const [graphqlAuthorization, setGraphqlAuthorization] = useState<IAuthorization | null>(null)
  const [ microsoftUserIdFromLocalStorage, saveMicrosoftUserIdToLocalStorage] = useLocalStorage<string | undefined>('vt-microsoftUserId', undefined)
  const [ companyIdFromLocalStorage, saveCompanyIdToLocalStorage] = useLocalStorage<string | undefined>('vt-companyId', undefined)

  const getContext = async () => {
    try {
      const msContext = await msAuth.getContext()

      if (!msContext.user?.id || !msContext.user?.tenant?.id) {
        throw new Error('user ID and team ID are not provided')
      }
      const id = msContext.user.id
      msContext.app.theme && dispatch(setTheme(msContext.app.theme as ThemeType))
      dispatch(setUserId(`microsoft-${id}`))
      const teamId = msContext.user.tenant.id
      const tenantId = msContext.user.tenant.id || ''
      const microsoftUser: IMicrosoftUser = {
        userId: id,
        teamId,
        tenantId,
      }
      setMicrosoftUser(microsoftUser)
      track('MICROSOFT_TAB_DASHBOARD_VIEWED', {
        tenantId: msContext.user.tenant.id || '',
        platform: 'microsoft',
      })
      setConsentLink(`https://login.microsoftonline.com/${teamId}/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`)

      const token = await msAuth.getMicrosoftToken()
      return login(token, id, teamId, msContext.user.userPrincipalName)
    } catch (e: any) {
      track('MICROSOFT_TAB_DASHBOARD_ERROR', {
        error: JSON.stringify(e),
        platform: 'microsoft',
      })
      Sentry.captureException(e)
      handleError(e)
    }
  }

  const handleError = async (error, tenantId?: string, token?: string) => {
    if (error?.suberror === 'consent_required' || error?.error === 'invalid_grant') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      track('MICROSOFT_TAB_DASHBOARD_CONSENT_REQUIRED', {
        ...error,
        platform: 'microsoft',
      })
      showPageError({
        title: <IntlMessages id="app.consentRequired.title" />,
        description: (
          <Paragraph>
            <IntlMessages id="app.consentRequired" values={{ link: (<a href={consentLink} target="_blank" rel="noopener noreferrer">{consentLink} <ExternalLink /></a>) }} />
          </Paragraph>
        ),
        errorType: 'warning',
      })
      return
    } else if (tenantId && token) {
      const userData: ICheckUserId = await getUserId(tenantId, token)
      if(userData.type === 'USER_NOT_FOUND' && userData.subscriptionStatus === 'canceled') {
        showPageError({
          title: <IntlMessages id="connect.subscriptionExpiredTitle" />,
          description: <IntlMessages id="error.subscriptionExpired" />,
          errorType: 'error',
        })
        return
      }

      if (userData.type === 'USER_NOT_FOUND') {
        showPageError({
          title: <IntlMessages id="error.auth.companyExists.title" />,
          description: (<>
            <Paragraph><IntlMessages id="error.auth.companyExists.line1" /></Paragraph>
            <Paragraph><IntlMessages id="error.auth.companyExists.line2" /></Paragraph>
            {
              (userData.adminContacts && userData.adminContacts.length > 0) ? (
                <ul>
                  {
                    userData.adminContacts.map(adminContact =>
                      <li key={adminContact.email}>
                        <Paragraph copyable={{
                          onCopy: () => void track('ORG_ALREADY_EXISTS_PAGE_EMAIL_BUTTON_CLICKED', {}),
                          tooltips: <IntlMessages id="connect.copyEmail" />,
                          text: adminContact.email,
                        }}>
                          <Text strong>{adminContact.name}</Text>, {adminContact.email}
                        </Paragraph>
                      </li>
                    )
                  }
                </ul>
              ) : (
                <Paragraph key={userData.contactEmail} copyable={{
                  onCopy: () => void track('ORG_ALREADY_EXISTS_PAGE_EMAIL_BUTTON_CLICKED', {}),
                  tooltips: <IntlMessages id="connect.copyEmail" />,
                  text: userData.contactEmail,
                }}>
                  <Text strong>{userData.contactEmail}</Text>
                </Paragraph>
              )
            }
            <Paragraph><IntlMessages id="error.auth.companyExists.line3" values={{
              support: <a href="mail:hello@vacationtracker.io">hello@vacationtracker.io</a>,
            }} /></Paragraph>
          </>),
          errorType: 'warning',
        })
        return
      }
    }
    track('MICROSOFT_TAB_MY_PROFILE_ERROR_SHOW_COMPLETE_SIGNUP', {
      error: error.toString(),
      platform: 'microsoft',
    })
    Sentry.captureException(error)
    setCompanyExist(false)
    if (error?.code !== 'NotAuthorizedException') {
      showPageError({
        title: <IntlMessages id="error.generic" />,
        description: (<>
          <Paragraph><IntlMessages id="error.generic.description" /></Paragraph>
          <Paragraph><Text code copyable>{ JSON.stringify(error, null, 4) }</Text></Paragraph>
        </>),
        errorType: 'error',
      })
    }
  }

  const asBase64EncodedJson = value => Buffer.from(JSON.stringify(value), 'utf8').toString('base64')

  const createAppSyncAuthorizedWebSocket = (getAppSyncAuthorizationInfo) => {
    return class extends WebSocket {
      // SubscriptionClient takes a fixed websocket url so we append query string parameters every time the websocket
      // is created, in case the authorization information has changed.
      constructor(url, protocols = undefined) {
        super(
          `${url}?header=${asBase64EncodedJson(getAppSyncAuthorizationInfo)}&payload=${asBase64EncodedJson({})}`,
          protocols
        )
      }

      send(originalData) {
        const data = this._tryParseJsonString(originalData)

        // AppSync's subscription event requires extensions
        // and a slightly different message format
        if (data?.payload?.query) {
          return super.send(JSON.stringify({
            ...data,
            payload: {
              data: JSON.stringify({
                query: data.payload.query,
                variables: data.payload.variables,
              }),
              extensions: {
                authorization: getAppSyncAuthorizationInfo,
              },
            },
          }))
        }

        return super.send(originalData)
      }

      // AppSync acknowledges GraphQL subscriptions with "start_ack" messages but SubscriptionClient cannot handle them
      set onmessage(handler) {
        super.onmessage = event => {
          if (event.data) {
            const data = this._tryParseJsonString(event.data)

            if (data && data.type === 'start_ack') {
              return
            }
          }

          return handler(event)
        }
      }

      _tryParseJsonString(jsonString) {
        try {
          return JSON.parse(jsonString)
        } catch (e) {
          return undefined
        }
      }
    }
  }

  const getAuth = async () => {
    const session = await Auth.currentSession()
    const jwtToken = session.getIdToken().getJwtToken()

    const GraphQlApiUrl = process.env.REACT_APP_GRAPHQL_ENDPOINT || ''
    const GraphQlHost = new URL(GraphQlApiUrl).hostname
    const header = {
      Authorization: jwtToken,
      host: GraphQlHost,
    }
    // For the real-time endpoint, see
    // https://docs.aws.amazon.com/appsync/latest/devguide/real-time-websocket-client.html#discovering-the-appsync-real-time-endpoint-from-the-appsync-graphql-endpoint
    const graphQlSubscriptionUrl = GraphQlApiUrl.includes('appsync-api') ? (
      // If it's not a custom domain, transform it
      GraphQlApiUrl
        .replace('https://', 'wss://')
        .replace('appsync-api', 'appsync-realtime-api')
    ) : (
      // If it's a custom domain, append '/realtime'
      GraphQlApiUrl.replace('https://', 'wss://') + '/realtime'
    )

    return {
      subscriptionUrl: graphQlSubscriptionUrl,
      header,
      jwtToken,
    }
  }

  const finalizeLogin = async (token: string, uid: string, tenantId: string, signInResponse: any) => {
    try {
      await Auth.sendCustomChallengeAnswer(signInResponse, token, { loginType: 'microsoft' })
      
      const msUserId = signInResponse.username
      saveMicrosoftUserIdToLocalStorage(msUserId)

      await loadUserDataFromLocalStorageOrApi(msUserId)
    } catch(error) {
      handleError(error)
    }
  }

  const login = async (token: string, uid: string, teamId: string, email?: string | undefined) => {
    try {
      const vtUser = email && await getUserByEmail(email)
      const signInResponse = await Auth.signIn(vtUser.username as string)
      const msToken = await getMicrosoftToken(token, teamId)

      if (msToken.error) {
        throw msToken
      }

      setAmplifySignInResponse(signInResponse)
      setAccessToken(msToken.access_token)
    } catch (error) {
      // Show login
      await handleError(error)
    }
  }

  async function loadUserDataFromLocalStorageOrApi(msUserId?: string) {
    try {
      if (!msUserId) {
        throw new Error('no user, load fresh')
      }
      const { jwtToken, subscriptionUrl, header } = await getAuth()

      if (!jwtToken || !subscriptionUrl || !header) {
        throw new Error('no auth data, load fresh')
      }

      dispatch(setUserId(msUserId))

      setGraphqlAuthorization(header)

      if (gqlClient) {
        gqlClient.setHeader('Authorization', jwtToken)

        gqlClient.subscriptionClient = new SubscriptionClient(subscriptionUrl, {
          reconnect: true,
          timeout: 5 * 60 * 1000, // 5 minutes is fine for AppSync
        }, createAppSyncAuthorizedWebSocket(header))
      }

      if (companyIdFromLocalStorage) {
        dispatch(setAuthCompany({ id: companyIdFromLocalStorage }))
        setLoggedIn(true)
      } else {
        const user = await fetchUser({ variables: { id: msUserId }})

        const companyId = user?.data?.getUser?.companyId
        dispatch(setAuthCompany({ id: companyId }))
        saveCompanyIdToLocalStorage
        setLoggedIn(true)
      }

      identify(msUserId, { visitedMyProfileMicrosoftTabs: true })
    } catch(err) {
      await getContext()
    }
  }

  useEffect(() => {
    if (!accessToken || !amplifySignInResponse || !microsoftUser) {
      return
    }
    finalizeLogin(accessToken, microsoftUser.userId, microsoftUser.tenantId, amplifySignInResponse)
  }, [accessToken, amplifySignInResponse, microsoftUser])

  useEffect(() => {
    // Use existing data or, if not available, load the context from Microsoft Teams tab
    loadUserDataFromLocalStorageOrApi(microsoftUserIdFromLocalStorage)
    page()
  }, [])

  return (<>
    { pageError &&
      <Alert
        type={pageError.errorType}
        message={pageError.title}
        description={pageError.description}
        style={{ margin: 12 }}
        showIcon
      />
    }
    { !pageError && !companyExist && <CompanyDoesNotExist microsoftUser={microsoftUser} /> }
    {
      !pageError && (!loggedIn || !graphqlAuthorization) && <CircularProgress />
    }
    { !pageError && companyExist && loggedIn && graphqlAuthorization && <>
      <PageComponent />
      <Notifications />
    </> }
  </>)
}


const CompanyDoesNotExist = ({ microsoftUser }: { microsoftUser: IMicrosoftUser | null }) => {
  const dispatch = useAppDispatch()
  const msAuth = new MicrosoftTeams()

  const computeAndSetLanguage = async () => {
    const msContext = await msAuth.getContext()
    const userLanguagePreferance = getPrefferedLanguage(LocaleEnum.en, msContext.app.locale.toLowerCase())
    dispatch(setLocale(userLanguagePreferance))
  }

  const completeSignup = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    try {
      // A/B test: if tenant ID begins with 0 or 1, trigger the In App Purchase flow
      // Change "x" to some other hex character (0-f) or range to enable the A/B test
      // For example .match(/[0-2]/i) will match 0, 1, or 2 as the first character of the tenant ID
      if (microsoftUser?.tenantId && microsoftUser?.tenantId.charAt(0).match(/[x]/i)) {
        event.preventDefault()
        await msAuth.openInAppPurchaseFlow()
        track('MICROSOFT_TAB_DASHBOARD_COMPLETE_SIGNUP_BUTTON_CLICKED', {
          tenantId: microsoftUser?.tenantId,
          variation: 'InAppPurchase',
          platform: 'microsoft',
        })
      }
      // Otherwise open link
      track('MICROSOFT_TAB_DASHBOARD_COMPLETE_SIGNUP_BUTTON_CLICKED', {
        tenantId: microsoftUser?.tenantId || '',
        variation: 'OpenDashboard',
        platform: 'microsoft',
      })
    } catch(err) {
      // Handle error and fallback to variation B
      track('MICROSOFT_TAB_DASHBOARD_COMPLETE_SIGNUP_BUTTON_CLICKED', {
        tenantId: microsoftUser?.tenantId || '',
        variation: 'OpenDashboard',
        fallback: true,
        platform: 'microsoft',
      })
    }
  }

  useEffect(() => {
    computeAndSetLanguage()
  }, [])

  return <Result
    status="success"
    subTitle={
      <div>
        <p><IntlMessages id="app.companyNotExist.description1" /></p>
        <p><IntlMessages id="app.companyNotExist.description2" /></p>
      </div>
    }
    extra={[
      <Button
        onClick={(event) => {
          completeSignup(event)
          return
        }}
        type="primary"
        key="console"
        target="_blank"
        href={dashboardCompleteSignupUrl}
      >
        <IntlMessages id="app.companyNotExist.getStarted" /> <ExternalLink />
      </Button>,
    ]}
  />
}

export default PageWrapper
